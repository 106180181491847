export const sectionsData = [
    {
        id: "edu",
        title: "Wykształcenie",
        content: '<p>Jestem magistrem psychologii o specjalności klinicznej oraz certyfikowaną specjalistką psychoterapii uzależnień. Moja praca jest nadzorowana przez superwizora RSPU. Ponadto, stale podnoszę kwalifikacje zawodowe, aby być na bieżąco z najnowszymi osiągnięciami w dziedzinie psychologii i psychoterapii. </p>'
    },
    {
        id: "exp",
        title: "Doświadczenie zawodowe",
        content: '<p>Doświadczenie zawodowe zdobywałam w ramach wolontariatu w Fundacji „Arka” zajmującej się postrehabilitacją osób uzależnionych od środków psychoaktywnych, a także w ramach stażu w ośrodku leczenia uzależnień MONAR przy ul. Tuszyńskiej w Łodzi. Ponadto zdobywałam doświadczenia w Centrum Pomocy Rodzinie oraz współpracując z Poradnią Stowarzyszenia MONAR i prywatnymi placówkami. </p>'
    },
    {
        id: "therapy",
        title: "Psychoterapia",
        content: `
            <p>Psychoterapia to leczenie poprzez rozmowę, które pomaga w głębszym rozumieniu swoich stanów emocjonalnych, a w efekcie poprawie funkcjonowania.
            W myśleniu psychoterapeutycznym  opieram się na rozumieniu psychoanalitycznym, technikach pracy z uzależnieniami i relacji terapeutycznej. Zapraszam pacjentów do namysłu nad tym jak nieświadome impulsy, przeszłe wydarzenia i relacje wpływają na ich życie w teraźniejszości.
            Zajmuję się terapią osób z uzależnieniami, doświadczających relacji z osobą z uzależnieniem (współuzależnienie, DDA), a także pacjentów przeżywających emocje w sposób zakłócający codzienność (życie osobiste lub/ i  zawodowe).
            </p>
        `
    },
    {   id: "psychological-support",
        title: "Wsparcie psychologiczne",
        content: "<p>Wsparcie psychologiczne to krótkotrwała forma pomocy skierowana do osób będących aktualnie w kryzysie życiowym. W ramach tej formy pracuję z pacjentami doświadczającymi trudności emocjonalnych związanych z kryzysowym wydarzeniem (np. śmierć w otoczeniu, rozwód, zmiana/ utrata pracy lub inne trudne dla danej osoby doświadczenia). Spotkania odbywają się regularnie i polegają na rozmowie o trudnościach, ich przyczynach i możliwych sposobach wytrzymywania oraz radzenia sobie z nimi.</p>"
    },
    {   id: "How-do-I-work?",
        title: "Jak pracuję?",
        content: "<p>Pracuję w oparciu o setting psychodynamiczny. Sesje terapeutyczne odbywają się z częstotliwością raz lub dwa razy w tygodniu o stałej dla danego pacjenta porze, trwają 50 minut. Wszystkie sesje są płatne. W razie pytań zapraszam do kontaktu telefonicznego.</p>"
    },
    {
        id: "professional-practice",
        title: "W ramach praktyki prowadzę:",
        content: '<ul class = "therapy-list"><li>psychoterapię uzależnień</li> <li>psychoterapię współuzależnień</li> <li>konsultacje psychologiczne</li> <li>poradnictwo psychologiczne</li?'
    },
    {
        id: "office-image",
        imageSrc: "https://i.postimg.cc/6qCZbQ6M/gabinet.jpg"
    }
];
